import { HttpParams } from '@angular/common/http';
import { InjectionToken } from '@angular/core';
import { AssignedId } from './assigned-id';
import { Coding } from './coding';
import { PatientAddress } from './patientaddress';

export type ResourceName = string;

export type EnumValueName = ResourceName;

/**
 * Token to inject environment variables. You can then inject this like so:
 * @Inject(EnvironmentVariablesService) private environmentVariables: EnvironmentVariables
 */
export const EnvironmentVariablesService = new InjectionToken<EnvironmentVariables>('EnvironmentVariables');

export interface Error {
  //type of message
  kind: string;
  //not to be used for users this is a developer error message
  message?: string;
  extraData?: any;
  //translatable key to be used in eg. toasts
  translationKey?: string;
  translationParams?: any;
  //status code
  status?: number;
  source?: any;
  errorName?: ResourceName;
  userResolvable?: boolean;

  fieldViolations?: any[];

  // debug info trace id
  traceId?: string;
}

export enum ErrorKind {
  UNAUTHENTICATED = 'tech_boot_grpc-unauthenticated',
}

export interface EnvironmentVariables {
  production: boolean;
  application: string;
  configFilename?: string;
  mapboxAccessToken: string;
}

export interface ContextResource {
  name: ResourceName;
  type: ContextResourceType;
}

export enum ContextResourceType {
  UNSPECIFIED = 'UNSPECIFIED',
  PATIENT = 'PATIENT',
  SUB_CONTACT = 'SUB_CONTACT',
}

export enum LinkedResourceType {
  MULTIMEDIA_REGISTRATION = 'multimedia/types/registration',
  LAB_REPORT = 'lab/types/report',
}

export interface Range {
  fromDate: Date;
  toDate: Date;
}

export interface Session {
  tenantName?: string;
  userName?: string;
  patientName?: string;
  subcontactName?: string;
}

export interface Person {
  name: ResourceName;
  sendVersion: string;
  abstractedTypeName: string;
  abstractedName: string;
  displayName: PersonName;
  pictureBlobName: ResourceName;
  identifiers: Partial<AssignedId>[];
  codes?: Coding[];
}

export interface PersonName {
  prefix?: string;
  nick?: string;
  given: string;
  otherGivens?: string[];
  family: string;
  suffix?: string;

  displayName?: string;
}

export function isPersonName(value: any): value is PersonName {
  return !!value?.['given'] && !!value?.['family'];
}

export interface Organisation {
  sendVersion: string;
  abstractedTypeName: string;
  abstractedName: string;
  displayName: OrganisationName;
  logoBlobName: ResourceName;
  identifiers: Partial<AssignedId>[];
  codes?: Coding[];
}

export interface OrganisationName {
  short: string;
  long?: string;
  abbreviation?: string;
}

export interface Actor {
  name?: string;
  sendVersion?: string;
  person?: Person;
  organisation?: Organisation;
}

export interface Picture {
  name: string;
  contentType: string;
  dataUrl: string;
  data?: string;
  sendVersion?: string;
}

export interface Decision {
  definition: ConsentDefinition;
  patientDecision: Consent;
}

export interface Consent {
  active: boolean;
  comment: string;
  definition: ConsentDefinition;
  discussedWith: string;
  name: string;
  patientInformed: boolean;
  registrationDate: string;
  revisionDate: string;
  sendVersion: string;
  startDate: string;
  value: ConsentValue;

  definitionName?: string;
}

export interface ConsentDefinition {
  name: string;
  key: string;
  display: string;
  defaultValue: ConsentValue;
  values: ConsentValue[];
  sendVersion: string;
}

export interface ConsentValue {
  display: string;
  key: string;
}

export interface Objective {
  name: string;
  sendVersion: string;
  title: string;
  goal: string;
}

export interface BlobMeta {
  contentType: string;
  fileName: string;
}

export interface DateRange {
  startTime: string;
  endTime: string;
}

/**
 * @deprecated Not very useful. For now pass updateMask explicitly.
 */
export function queryParamsForEmptyFields<T>(data: T): HttpParams | null {
  const nullValues: [string, T][] = Object.entries(data as { [s: string]: T } | ArrayLike<T>).filter(
    ([k, v]) => v === null || v === undefined || v === '' || (Array.isArray(v) && v.length === 0),
  );
  const queryParams: string[] = [];
  let httpParams: HttpParams = new HttpParams();

  nullValues.forEach((value) => queryParams.push(value[0]));
  httpParams = httpParams.append('updateMask', queryParams.join(','));

  if (httpParams.get('updateMask') !== '') {
    return httpParams;
  }

  return null;
}

export interface ClockTime {
  hours: number;
  minutes: number;
  seconds?: number;
}

export function isClockTime(value: any): value is ClockTime {
  return value?.hours !== undefined && value?.minutes !== undefined;
}

export function stripVersion(resourceName: ResourceName) {
  const parts = resourceName.split('/');
  parts.pop();
  parts.pop();
  return parts.join('/');
}

export function toLocalClockTime(date: Date): ClockTime {
  return {
    hours: date.getHours(),
    minutes: date.getMinutes(),
    seconds: date.getSeconds(),
  };
}

// note: order is important as date-utils.sortDateTimes relies on it!
export enum DateTimePrecision {
  PRECISION_UNSPECIFIED = 'PRECISION_UNSPECIFIED',
  MICROSECOND = 'MICROSECOND',
  MILLISECOND = 'MILLISECOND',
  SECOND = 'SECOND',
  DAY = 'DAY',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export interface DateTime {
  // ISO compliant datetime string
  value: string;
  precision: DateTimePrecision;
  location?: string;
}

export interface PersonData {
  birthDate: DateTime;
  personName: PersonName;
  gender: string;
  administrativeGender: string;
  languages: string[]; // order - first one is mother tongue
  nationalities: string[];
  placeOfBirth: { country: string; city: string };
  civilStatus: string;

  assignedIds: AssignedId[];

  // contactInfo?: ContactInfo;

  // contactPointIds: [];
  contactPoints?: ContactPoint[];
  writtenLanguage?: string;
}

export interface ContactPoint {
  name?: string;
  sendVersion?: string;
  comment?: string;
  system: ContactPointSystem;
  use: ContactPointUse;
  value: string;

  addressName?: ResourceName;
}

export enum ContactPointSystem {
  UNSPECIFIED = 'System_UNSPECIFIED',
  PHONE = 'System_PHONE',
  MOBILE = 'System_MOBILE',
  EMAIL = 'System_EMAIL',
  FAX = 'System_FAX',
}

export enum ContactPointUse {
  UNSPECIFIED = 'Use_UNSPECIFIED',
  HOME = 'Use_HOME',
  MOBILE = 'Use_MOBILE',
  PERSONAL = 'Use_PERSONAL',
  WORK = 'Use_WORK',
}

export interface AddressWithContactPoints {
  address: PatientAddress;
  contactPoints: ContactPoint[];
}

export interface TypedRef {
  typeName: string;
  name: string;
}

export enum TypedRefTypes {
  SUBCONTACT = 'ehrbase/types/subcontact',
}

export type Immutable<T> = {
  readonly [K in keyof T]: Immutable<T[K]>;
};

export type BigDecimal = {
  value: string;
};

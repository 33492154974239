<div class="btn-group {{ gridClasses }}" role="group" nxhToggle [pills]="pills">
  <ng-container *ngFor="let option of options; let i = index">
    <!-- for the name we are setting both attribute and property because of
    https://github.com/angular/angular/issues/11757-->
    <input
      type="radio"
      class="btn-check"
      [formControl]="formControl"
      [value]="option.value"
      [attr.checked]="option.value === formControl.value"
      nxhDataTest="{{ testPrefix }}-{{ i }}"
      [disabled]="option.disabled || formControl.disabled"
      id="{{ testPrefix }}-{{ option.value }}-{{ i + randomId }}"
      [attr.name]="randomId"
      [name]="randomId"
      autocomplete="off"
    />
    <label
      class="btn btn-primary toggle-list-label"
      [class.active]="option.value === formControl.value"
      [class.disabled]="option.disabled || formControl.disabled"
      [for]="option.disabled ? '' : testPrefix + '-' + option.value + '-' + (i + randomId)"
    >
      <ng-container *ngIf="optionDirective?.template; else noTemplate">
        <ng-container
          *ngTemplateOutlet="
            optionDirective.template;
            context: { $implicit: option, active: option.value === formControl.value, showRadio: showRadio }
          "
        ></ng-container>
      </ng-container>
      <ng-template #noTemplate>
        <ng-container
          *ngTemplateOutlet="
            withBinders;
            context: {
              $implicit: option,
              active: option.value === formControl.value,
              label: noLabel ? undefined : bindLabel ? option[bindLabel] : option.label ? (option.label | i18next) : '',
              description: bindDescription ? option[bindDescription] : undefined,
              showRadio: showRadio,
            }
          "
        />
      </ng-template>
    </label>
  </ng-container>
</div>

<ng-template #withBinders let-showRadio="showRadio" let-label="label" let-description="description" let-active="active">
  <nxh-toggle-list-option [showRadio]="showRadio" [label]="label" [description]="description" [active]="active" />
</ng-template>

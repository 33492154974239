<div class="toggle-switch">
  <input
    class="toggle-switch-checkbox"
    type="checkbox"
    #checkbox
    [defaultChecked]="defaultChecked"
    (change)="toggle(checkbox.checked)"
  />
  <label class="toggle-switch-label">
    <span class="toggle-switch-label-background" [ngStyle]="{ transitionDuration: transitionDuration$ | async }"></span>
    <fa-icon class="toggle-switch-label-icon left" [icon]="iconLeft"></fa-icon>
    <fa-icon class="toggle-switch-label-icon right" [icon]="iconRight"></fa-icon>
  </label>
</div>

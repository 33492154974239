<div class="input-group">
  <div class="input-group-prepend">
    <ng-select
      class="phone__select-country"
      #select
      [items]="countries"
      [clearable]="false"
      [disabled]="(disabled | async) === true"
      [ngModel]="countryCode"
      (ngModelChange)="onSelectCountryCode($event)"
    >
    </ng-select>
  </div>
  <input
    class="form-control phone__value"
    #input
    (ngModelChange)="onPhonenumberChange($event)"
    [ngModel]="formattedPhonenumber"
    [disabled]="(disabled | async) === true"
    (blur)="onTouch()"
    type="tel"
    [placeholder]="placeholder"
  />
</div>

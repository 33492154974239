// Suppressed - will be moved to feature lib
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'nxh-task-check',
  templateUrl: './task-check.component.html',
  styleUrls: ['./task-check.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TaskCheckComponent),
      multi: true,
    },
  ],
})
export class TaskCheckComponent implements ControlValueAccessor {
  @Input() id;
  @Input() value = false;
  @Input() disabled = false;
  @Input() tooltip: string;
  constructor() {}

  onChange = (value: boolean) => {};
  onTouched = () => {};

  writeValue(obj: any): void {
    this.value = obj;
  }

  changeValue(event) {
    this.onChange(event.target.checked);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}

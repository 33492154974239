import { ChangeDetectorRef, Injectable, isDevMode, OnDestroy, Optional } from '@angular/core';
import { AbstractControl, isFormGroup, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Error } from '@nexuzhealth/shared-domain';
import { default_timeout_interval_millis, default_timeout_warning_millis } from '@nexuzhealth/shared-util';
import { I18NextPipe } from 'angular-i18next';
import { ToastrService } from 'ngx-toastr';
import { NEVER, Observable, ReplaySubject, Subject, TimeoutError, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * @deprecated Use the nh-form framework in combination with FormHelper
 */
@Injectable({ providedIn: 'any' })
export class FormHelperService implements OnDestroy {
  private destroy$ = new Subject<void>();

  constructor(
    private cdr: ChangeDetectorRef,
    @Optional() private route: ActivatedRoute | null,
    @Optional() private router: Router | null,
    @Optional() private toastrService: ToastrService | null,
    @Optional() private i18nextpipe: I18NextPipe | null,
  ) {}

  private busy$!: Subject<void>;

  // TODO: This is a hot observable, this should be cold because it does side effects, which is weird
  // since observables are lazy most of the time
  submit<FormValue = any, ReturnValue = any>(
    form: UntypedFormGroup,
    request: (formRawValue?: FormValue) => Observable<ReturnValue>,
    options: FormHelperSubmitOptions = {
      reEnableOnSuccess: false,
    },
  ): Observable<ReturnValue> {
    // ReplaySubject because when the request is executed synchronously, the subject might be complete before
    // it has been subscribed to.
    this.busy$ = new Subject<void>();
    const subj = new ReplaySubject<ReturnValue>(1);
    form.markAllAsTouched();
    if (form.valid) {
      if (options.beforeRequest) {
        const cont = options.beforeRequest();
        if (cont === false) {
          return subj.asObservable();
        }
      }

      // disable form fields
      const disabledControls = getDisabledControls(form);
      form.disable({ emitEvent: false });

      const timeouts = {
        ...options?.timeouts,
        warningMillis: default_timeout_warning_millis,
        intervalMillis: default_timeout_interval_millis,
        showTimoutError: true,
      };

      if (timeouts?.warningMillis !== -1) {
        timer(timeouts.warningMillis, timeouts.intervalMillis)
          .pipe(takeUntil(this.busy$))
          .subscribe(() => {
            this.showTimeoutWarning();
          });
      }

      request(form.getRawValue()).subscribe({
        next: (result) => {
          this.stopWarning();

          if (options.reEnableOnSuccess) {
            form.enable({ emitEvent: false });
            disabledControls.forEach((control) => control.disable({ emitEvent: false }));
          }

          if (options.redirectUrl) {
            if (isDevMode() && (!this.router || !this.route)) {
              console.warn('If using redirectUri, you need to have router and route injected');
            } else {
              const navigationCommands = Array.isArray(options.redirectUrl)
                ? options.redirectUrl
                : options.redirectUrl.split('/');

              this.router?.navigate(navigationCommands, {
                relativeTo: this.route,
              });
            }
          }

          if (options.formName) {
            if (!options.setIsDirty) {
              if (isDevMode()) {
                console.warn(`When providing options.formName, you also have to provide a setIsDirty`);
              }
            } else {
              options.setIsDirty(options.formName, false);
            }
          }

          if (options.successToast) {
            if (isDevMode() && (!this.i18nextpipe || !this.toastrService)) {
              console.warn('If using successtoast, you need to have toastrservice and i18next injected');
            } else {
              const message = this.i18nextpipe?.transform(options.successToast?.message);
              const title = this.i18nextpipe?.transform(options.successToast?.title || '');
              this.toastrService?.success(message, title);
            }
          }

          subj.next(result);
          subj.complete();
          this.cdr.markForCheck();
        },
        error: (err) => {
          this.stopWarning();

          if (err instanceof TimeoutError && timeouts?.showTimoutError) {
            this.showTimeoutError();
          }

          form.enable({ emitEvent: false });
          disabledControls.forEach((control) => control.disable({ emitEvent: false }));
          if (options.errorToast) {
            if (isDevMode() && (!this.i18nextpipe || !this.toastrService)) {
              console.warn('If using errorToast, you need to have toastrservice and i18next injected');
            } else {
              const { errorToast } = options;
              const errorToastConfig = typeof errorToast === 'function' ? errorToast(err) : errorToast;

              const message = this.i18nextpipe?.transform(errorToastConfig.message);
              const title = this.i18nextpipe?.transform(errorToastConfig.title || '');
              this.toastrService?.error(message, title);
            }
          }
          subj.error(err);
          this.cdr.markForCheck();
        },
      });
    } else {
      return NEVER;
    }

    return subj.asObservable();
  }

  private stopWarning() {
    if (this.busy$ && !this.busy$.closed) {
      this.busy$.next();
      this.busy$.complete();
    }
  }

  private showTimeoutWarning() {
    const warningTitle = this.i18nextpipe?.transform('_loading-states._warning-timeout.title');
    const warningDescription = this.i18nextpipe?.transform('_loading-states._warning-timeout.description');
    this.toastrService?.warning(warningTitle, warningDescription);
  }

  private showTimeoutError() {
    const errorTitle = this.i18nextpipe?.transform('_loading-states._error-timeout.title');
    const errorDescription = this.i18nextpipe?.transform('_loading-states._error-timeout.description');
    this.toastrService?.error(errorTitle, errorDescription);
  }

  ngOnDestroy(): void {
    this.stopWarning();
    this.destroy$.next();
    this.destroy$.complete();
  }
}

function getDisabledControls(control: AbstractControl) {
  const disabledControls: AbstractControl[] = [];
  collectDisabledControls(control, disabledControls);
  return disabledControls;
}

function collectDisabledControls(control: AbstractControl, result: AbstractControl[] = []) {
  if (isFormGroup(control)) {
    Object.values(control.controls).forEach((childControl: AbstractControl) => {
      collectDisabledControls(childControl, result);
    });
  } else if (control.disabled) {
    result.push(control);
  }
}

interface ToastSettings {
  title?: string;
  message: string;
}

export interface FormHelperSubmitOptions {
  beforeRequest?: () => void | boolean;
  // submit button to disbale while submitting.
  // NOTE: this only works when the form has no autoValidateDirective
  reEnableOnSuccess?: boolean;
  // Url to redirect to after success. Relative to current route
  redirectUrl?: string | string[];
  successToast?: ToastSettings;
  // i18next keys for the toast on error
  errorToast?: ToastSettings | ((err: Error) => ToastSettings);
  // Used for disabling the dirty navigation
  formName?: string;
  setIsDirty?: (formName: string, dirty: boolean) => void;
  timeouts?: {
    warningMillis?: number;
    intervalMillis?: number;
    showTimoutError?: boolean;
  };
}

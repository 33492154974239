<div class="form-check" [class.form-switch]="switch">
  <input
    #input
    type="checkbox"
    class="form-check-input"
    [id]="id"
    [checked]="isChecked"
    (change)="onChanged()"
    (blur)="onTouched()"
    [disabled]="disabled"
  />
  <label class="form-check-label" [for]="id" [class.required]="required" [class.disabled]="disabled">
    <span *ngIf="label">{{ label }}</span>
    <ng-content></ng-content>
  </label>
</div>

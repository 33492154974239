import { Injectable, OnDestroy } from '@angular/core';
import { default_timeout_interval_millis, default_timeout_warning_millis } from '@nexuzhealth/shared-util';
import { I18NextPipe } from 'angular-i18next';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, merge, Observable, of, Subject, throwError, TimeoutError, timer } from 'rxjs';
import { catchError, filter, takeUntil, tap } from 'rxjs/operators';
import { FormHelperDirective } from '../components/form-helper/form-helper.directive';
import { SubmitFormButtonComponent } from '../components/form-helper/submit-form-button/submit-form-button.component';
import { IGNORE_INVALIDABLE_CONTROL, INVALIDABLE_CONTROL } from './form-helper.domain';

const defaultOptions: FormHelperOptions = {
  resetOnSuccess: true,
  markPristineWithoutResetAfterSuccess: false,
  validate: true,
  timeouts: {
    warningMillis: default_timeout_warning_millis,
    intervalMillis: default_timeout_interval_millis,
    showTimoutError: true,
  },
};

@Injectable()
export class FormHelper implements OnDestroy {
  public formHelperDirective!: FormHelperDirective;
  submitting$ = new BehaviorSubject(false);
  private destroy$ = new Subject<void>();
  public sourceSubmitButton?: SubmitFormButtonComponent;

  constructor(
    private toastrService: ToastrService,
    private i18n: I18NextPipe,
  ) {}

  /**
   * @deprecated. Use this.form as defined in your component
   */
  get form() {
    return this._form;
  }

  private get _form() {
    return this.formHelperDirective.form;
  }

  setFormHelperDirective(formHelperDirective: FormHelperDirective) {
    this.formHelperDirective = formHelperDirective;
  }

  /*
        ---------------TODO this should be reworked so it can be integrated with the store-----------------------
         */
  initSimpleSubmit<FormValue>(
    internalCallback: (formRawValue: FormValue) => void,
    givenOptions: Partial<Omit<FormHelperOptions, 'beforeRequest'>> = defaultOptions,
  ): void {
    const newDefaultOptions: Omit<FormHelperOptions, 'beforeRequest'> = defaultOptions;
    const options = { ...newDefaultOptions, ...givenOptions };
    if (options.validate) {
      // omdat focus op eerste el ligt => eigenlijk is .submitted css niet nodig
      this._form.markAllAsTouched();

      if (!this._form.valid) {
        const firstInvalidElement = this.formHelperDirective.nativeElement.querySelector(
          `.${INVALIDABLE_CONTROL}.ng-invalid:not(.${IGNORE_INVALIDABLE_CONTROL})`,
        );
        setFocus(firstInvalidElement);
        return;
      }
    }

    this.submitting$.next(true);
    this.startShowingTimeouts(options.timeouts);

    return internalCallback(this._form.getRawValue());
  }

  successfulSubmit(resetOnSuccess: boolean | reset_config) {
    this.submitting$.next(false);
    this.formHelperDirective.reset(resetOnSuccess);
  }

  errorOnSubmit(err: any) {
    this.submitting$.next(false);

    if (err instanceof TimeoutError && defaultOptions.timeouts?.showTimoutError) {
      this.showTimeoutError();
    }
  }

  /*
       ------------------------------------------------------------------------------------------------------------
        */

  submit<ReturnValue>(
    request: () => Observable<ReturnValue>,
    givenOptions?: Partial<FormHelperOptions>,
  ): Observable<ReturnValue>;
  /**
   * @deprecated The request function you pass as a first parameter, should *not* have a "FormValue" parameter
   * anymore. It is recommended to get the form's value through this.form.value or this.form.getRawValue(),
   * this way you are fully leveraging Angular's new "typesafe forms" feature.
   */
  submit<FormValue, ReturnValue>(
    request: (formRawValue: FormValue) => Observable<ReturnValue>,
    givenOptions?: Partial<FormHelperOptions>,
  ): Observable<ReturnValue>;
  submit<FormValue, ReturnValue>(
    request: (formRawValue: FormValue) => Observable<ReturnValue>,
    givenOptions: Partial<FormHelperOptions> = defaultOptions,
  ): Observable<ReturnValue> {
    const options: FormHelperOptions = { ...defaultOptions, ...givenOptions };

    if (options.validate) {
      // omdat focus op eerste el ligt => eigenlijk is .submitted css niet nodig
      this._form.markAllAsTouched();

      if (!this._form.valid) {
        const firstInvalidElement = this.formHelperDirective.nativeElement.querySelector(
          `.${INVALIDABLE_CONTROL}.ng-invalid:not(.${IGNORE_INVALIDABLE_CONTROL})`,
        );
        setFocus(firstInvalidElement);
        return of();
      }
    }

    if (typeof options.beforeRequest === 'function') {
      const cont = options.beforeRequest();
      if (cont === false) {
        return of();
      }
    }

    this.submitting$.next(true);
    this.startShowingTimeouts(options.timeouts);

    return request(this._form.getRawValue()).pipe(
      tap(() => {
        this.submitting$.next(false);
        if (options.markPristineWithoutResetAfterSuccess) {
          this.formHelperDirective.markPristine();
        } else {
          this.formHelperDirective.reset(options.resetOnSuccess);
        }
      }),
      catchError((err) => {
        this.submitting$.next(false);

        if (err instanceof TimeoutError && options.timeouts?.showTimoutError) {
          this.showTimeoutError();
        }
        return throwError(() => err);
      }),
    );
  }

  private startShowingTimeouts(timeouts: FormHelperOptions['timeouts']) {
    const submitted$ = this.submitting$.pipe(filter((submitting) => !submitting));
    const stopTimeoutWarning$ = merge(submitted$, this.destroy$);

    if (timeouts.warningMillis && timeouts.warningMillis !== -1) {
      (timeouts.intervalMillis ? timer(timeouts.warningMillis, timeouts.intervalMillis) : timer(timeouts.warningMillis))
        .pipe(takeUntil(stopTimeoutWarning$))
        .subscribe(() => this.showTimeoutWarning());
    }
  }

  private showTimeoutWarning() {
    const warningTitle = this.i18n.transform('_loading-states._warning-timeout.title');
    const warningDescription = this.i18n.transform('_loading-states._warning-timeout.description');
    this.toastrService.warning(warningTitle, warningDescription);
  }

  private showTimeoutError() {
    const errorTitle = this.i18n.transform('_loading-states._error-timeout.title');
    const errorDescription = this.i18n.transform('_loading-states._error-timeout.description');
    this.toastrService.error(errorTitle, errorDescription);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

export function setFocus(el: HTMLElement) {
  if (!el) {
    return;
  }

  if (el instanceof HTMLInputElement || el instanceof HTMLTextAreaElement || el instanceof HTMLSelectElement) {
    el.focus();
  } else {
    // looking for focusable in angular component
    const childEl: HTMLElement | null = el.querySelector('input,textarea,select');
    childEl?.focus();
  }
}

export type reset_config = 'reset-on-config' | 'reset-initial-value-on-success' | 'noop';

export interface FormHelperOptions {
  beforeRequest?: () => void | boolean;
  /**
   * @default true
   */
  resetOnSuccess: boolean | reset_config;

  /**
   * Temporary measure to mark the form pristine (clears the DirtyCheck's FormStore) without calling the
   * Form's reset() method. In the future, once https://jira.uz.kuleuven.ac.be/browse/FEG-704 is fixed, this
   * will become the default
   */
  markPristineWithoutResetAfterSuccess: boolean;
  /**
   * If true, the form will be validated before the request is sent.
   * @default true
   */
  validate: boolean;
  timeouts: {
    warningMillis?: number;
    intervalMillis?: number;
    showTimoutError?: boolean;
  };
}

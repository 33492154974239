// Suppressed - component needs refactoring (HN-12077)
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DAY } from '@nexuzhealth/shared-domain';
import { difference } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToggleListOption } from '@nexuzhealth/shared-ui-toolkit/toggle';

@Component({
  selector: 'nxh-days-selector',
  templateUrl: './days-selector.component.html',
})
export class DaysSelectorComponent implements OnInit, OnChanges, OnDestroy {
  selectDay = new UntypedFormControl();
  daysSelectorFormGroup = new UntypedFormGroup({
    selectDay: this.selectDay,
  });

  @Input() selected: DAY[] = [];

  @Input() set disabled(value: boolean | undefined) {
    if (value != null) {
      if (value && !this.selectDay.disabled) {
        this.selectDay.disable({ emitEvent: false });
      } else if (!value && this.selectDay.disabled) {
        this.selectDay.enable({ emitEvent: false });
      }
    }
  }

  @Output() addDay = new EventEmitter<DAY>();
  @Output() removeDay = new EventEmitter<DAY>();
  @Output() selectedDays = new EventEmitter<DAY[]>();

  days = Object.keys(DAY).reduce((acc, curr) => {
    return [...acc, { label: DAY[curr], value: DAY[curr] }];
  }, [] as ToggleListOption[]);

  private destroy$$ = new Subject<void>();

  ngOnInit(): void {
    this.setValue();
    this.setupListeners();
  }

  // public for testing purposes
  handleChange(selected: DAY[]) {
    this.selectedDays.emit(selected);

    const removed = difference(this.selected, selected);
    if (removed.length > 0) {
      this.onRemoveDay(removed[0]);
      return;
    }
    const added: DAY[] = difference(selected, this.selected);
    if (added.length > 0) {
      this.onAddDay(added[0]);
      return;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const change = changes['selected'];
    if (change && !change.isFirstChange()) {
      this.setValue();
    }
  }

  onRemoveDay(day: DAY) {
    this.removeDay.emit(day);
  }

  onAddDay(day: DAY) {
    this.addDay.emit(day);
  }

  ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.complete();
  }

  private setValue() {
    this.selectDay.setValue(this.selected, { emitEvent: false });
  }

  private setupListeners() {
    this.selectDay.valueChanges.pipe(takeUntil(this.destroy$$)).subscribe((val) => {
      this.handleChange(val);
    });
  }
}
